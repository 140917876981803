
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use "sass/abstracts" as *;
// @use "sass/main.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

// Define the theme object.
$cbox-web-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

$mdc-button-border-radius: 16px;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($cbox-web-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($cbox-web-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($cbox-web-theme);

$wrapper-min-margin-inline: 1rem;
$wrapper-max-width: 75rem;
$wrapper-max-width-narrow: 62.5rem;
$wrapper-max-width-wide: 87.5rem;

/* Order */
@layer reset, primeng;

.wrapper {
  --padding: #{$wrapper-min-margin-inline} * 2;
  --max-width: #{$wrapper-max-width};

  width: min(100% - var(--padding), var(--max-width));
  margin-inline: auto;
}

.wrapper[data-type="narrow"] {
  --max-width: #{$wrapper-max-width-narrow};
}
.wrapper[data-type="wide"] {
  --max-width: #{$wrapper-max-width-wide};
}
.wrapper[data-type="full-bleed"] {
  --max-width: 100%;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.btn {
  &.mat-mdc-button,
  &.mat-mdc-raised-button,
  &.mdc-button--unelevated,
  &.mdc-button--outlined {
    border-radius: .25rem;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.md-64 { font-size: 64px; }

.material-symbols-rounded.md-18 { font-size: 18px; }
.material-symbols-rounded.md-24 { font-size: 24px; }
.material-symbols-rounded.md-36 { font-size: 36px; }
.material-symbols-rounded.md-48 { font-size: 48px; }
.material-symbols-rounded.md-64 { font-size: 64px; }

.table-container {
  max-height: 31.25rem;
  overflow: auto;

  table {
    --mat-table-row-item-outline-color: #{$color-neutral-200};
  }
}

.dialog-header {
  display: flex;
  align-items: start;
  padding: 1.25rem 1.5rem 0;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  button, p-button {
    margin-left: auto;
  }
}

.mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: #{$border-radius-1};
  --mdc-dialog-container-color: var(--surface-a);
  --mdc-dialog-supporting-text-color: var(--surface-900);
}

.divider {
  height: 100%;
  width: 1px;
  background-color: var(--surface-200);
}

.leaflet-marker-icon {
  background-color: transparent;
  border: none;
}

.blurred-div-blur {
  filter: blur(1px);
  pointer-events: none;
}

.blurred-div-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.blurred-div {
  backdrop-filter: blur(0.5px);
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}


